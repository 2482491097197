const LPC_API_URL = process.env.BACKEND_API_URL;

const ApiLpc = {
    AUTH: {
        SIGN_IN: `${LPC_API_URL}/login`,
        SIGN_OUT: `${LPC_API_URL}/account/logout`,
        CREATE_ACCOUNT: `${LPC_API_URL}/account`,
        UPDATE_ACCOUNT: `${LPC_API_URL}/account`,
        UPDATE_PROFILE_PICTURE: `${LPC_API_URL}/participation/{1}/update-profile-photo`,
        VERIFY_EMAIL: `${LPC_API_URL}/account/verify-email`,
        MISSING_INFORMATIONS: `${LPC_API_URL}/account/missing-informations`,
        USER_HAS_POTS: `${LPC_API_URL}/account/has-pots`,
        PASSWORD_FORGOTTEN: `${LPC_API_URL}/account/password-forget`,
        PASSWORD_CHANGE_CONFIRM: `${LPC_API_URL}/account/new-password`,
        GOOGLE_CONNECT: `${LPC_API_URL}/google-connect`,
        GOOGLE_AUTHENTICATOR: `${LPC_API_URL}/account/init-google-authenticator`,
        GOOGLE_AUTHENTICATOR_IDENTIFIED: `${LPC_API_URL}/account/init-google-authenticator-identified`,
        DOWNLOAD_BACKUP_CODES: `${LPC_API_URL}/backup-codes/download`,
        GET_BACKUP_CODES: `${LPC_API_URL}/backup-codes`,
        FACEBOOK_CONNECT: `${LPC_API_URL}/facebook-connect`,
        USER_ACCOUNT: `${LPC_API_URL}/account`,
        USER_CONTACT_INFORMATIONS: `${LPC_API_URL}/account/informations`,
        COOKIES_CONSENT: `${LPC_API_URL}/cookies`,
        VERIFY_PASSWORD: `${LPC_API_URL}/account/verify`,
        RESEND_SMS: `${LPC_API_URL}/account/login-resend-sms`,
        SEND_SMS: `${LPC_API_URL}/account/send-tfa-code-connection`,
        RESEND_SMS_IDENTIFIED: `${LPC_API_URL}/account/resend-sms-identified`,
        VALIDATE_SMS: `${LPC_API_URL}/account/validate-sms-auth`,
        VALIDATE_SMS_IDENTIFIED: `${LPC_API_URL}/account/validate-sms-auth-identified`,
        DEACTIVATE_TFA: `${LPC_API_URL}/account/deactivate-tfa`,
        SEND_TFA_CODE: `${LPC_API_URL}/account/send-tfa-code`,
        VALIDATE_GOOGLE_AUTH: `${LPC_API_URL}/account/validate-google-auth`,
        VALIDATE_GOOGLE_AUTH_IDENTIFIED: `${LPC_API_URL}/account/validate-google-auth-identified`,
        SMS_AUTH: `${LPC_API_URL}/account/init-sms-auth`,
        SMS_AUTH_IDENTIFIED: `${LPC_API_URL}/account/init-sms-auth-identified`,
        EMAIL_CHANGE: `${LPC_API_URL}/account/emails/{1}`,
        SIGN_IN_AFTER_2FA_CONFIG: `${LPC_API_URL}/account/connect-after-config`,
    },
    PARTNER: {
        GET_DETAILS: `${LPC_API_URL}/partner?partnerUniq={1}`,
        GET_FAVORITE_PARTNERS: `${LPC_API_URL}/partner/month-partner`,
        GET_SUB_CATEGORIES: `${LPC_API_URL}/partners/sub-categories/{1}`,
        GET_ALL_PARTNERS: `${LPC_API_URL}/partner`,
        GET_ONE_PARTNER: `${LPC_API_URL}/partners/{1}`,
        GET_ONE_GIFT_CARD: `${LPC_API_URL}/gift-card/{1}`,
        GENERATE_ONE_GIFT_CARD: `${LPC_API_URL}/pools/{1}/gift-card`,
        INIT_LIMONETIK_PAYMENT: `${LPC_API_URL}/payment-via-lpc/{1}`,
        VALIDATE_LIMONETIK_PAYMENT: `${LPC_API_URL}/payment-via-lpc/{1}`,
        INIT_EXTRA_PAYMENT: `${LPC_API_URL}/pools/{1}/init-extra-payment/{2}`,
        INIT_LIMONETIK_EXTRA_PAYMENT: `${LPC_API_URL}/partner/{1}/init-extra-payment/{2}`,
        VALIDATE_LIMONETIK_EXTRA_PAYMENT: `${LPC_API_URL}/payment-via-lpc/{1}/validate-extra-payment/{2}`,
        VALIDATE_EXTRA_PAYMENT: `${LPC_API_URL}/pools/{1}/validate-extra-payment/{2}`,
    },
    POT: {
        CREATE_POT: `${LPC_API_URL}/pools`,
        GET_POT: `${LPC_API_URL}/pools/{1}`,
        CHECK_POT_EXIST: `${LPC_API_URL}/pools/{1}/exist`,
        DELETE_POT: `${LPC_API_URL}/pools/{1}?refund={2}`,
        UPDATE_TITLE: `${LPC_API_URL}/pools/{1}/title`,
        UPDATE_MPP_PARENT: `${LPC_API_URL}/pools/{1}/mpp-parent`,
        GET_POT_SETTINGS: `${LPC_API_URL}/pools/{1}/params`,
        UPDATE_POT_SETTINGS_GENERAL: `${LPC_API_URL}/pools/{1}/params`,
        UPDATE_POT_SETTINGS_PARTICIPATIONS: `${LPC_API_URL}/pools/{1}/contribution-type`,
        UPDATE_POT_SETTINGS_CONFIDENTIALITY: `${LPC_API_URL}/pools/{1}/privacy`,
        OFFER_POT: `${LPC_API_URL}/pools/{1}/offer`,
        CANCEL_OFFER_POT: `${LPC_API_URL}/pools/{1}/offer/cancel`,
        UPLOAD_POT_IMAGE: `${LPC_API_URL}/pools/{1}/image-upload`,
        REMOVE_POT_IMAGE: `${LPC_API_URL}/pools/{1}/image-remove`,
        UPDATE_POT: `${LPC_API_URL}/pools/{1}`,
        SEND_INVITES: `${LPC_API_URL}/pools/{1}/send-invites`,
        GET_EMAIL_FROM_XLS: `${LPC_API_URL}/contacts/email-from-xlsx`,
        CONTACT_ORGANISER: `${LPC_API_URL}/pools/{1}/contact-organizer`,
        UPDATE_ORGANISER: `${LPC_API_URL}/pools/{1}/update-organizer`,
        THANK_PARTICIPANTS: `${LPC_API_URL}/pools/{1}/thank-participants`,
        GENERATE_DESCRIPTION: `${LPC_API_URL}/generate-description`,
        PARTICIPATE: `${LPC_API_URL}/pools/{1}/participate`,
        ADD_MESSAGE: `${LPC_API_URL}/pools/{1}/add-message`,
        ADD_MESSAGE_ANONYM: `${LPC_API_URL}/pools/{1}/payments/{2}/wish-card`,
        UPDATE_OBJECTIVE: `${LPC_API_URL}/pools/{1}/set-objective`,
        GET_ALL_PARTICIPANTS: `${LPC_API_URL}/pools/{1}/participants?cursor={2}&pageSize={3}`,
        CREATE_TRANSFER: `${LPC_API_URL}/pools/{1}/init-transfer`,
        GET_POT_HISTORY: `${LPC_API_URL}/pools/{1}/history`,
        GET_POT_CADO_CARD: `${LPC_API_URL}/pools/{1}/cado-card`,
        GET_ALL_NOTIFICATIONS: `${LPC_API_URL}/pools/{1}/notifications?cursor={2}&pageSize={3}`,
        GET_PAYMENT: `${LPC_API_URL}/payment/{1}`,
        FINALIZE_PAYMENT: `${LPC_API_URL}/pools/{1}/payment`,
        SAVE_DALENYS_BLOCKED_IP: `${LPC_API_URL}/caddies/{1}/save-dalenys-blocked-ip`,
        GET_IBANS: `${LPC_API_URL}/ibans`,
        ADD_IBAN: `${LPC_API_URL}/ibans`,
        GET_OFFERED_POT: `${LPC_API_URL}/offered-pool-infos/{1}`,
        ACCEPT_OFFERED_POT: `${LPC_API_URL}/pot-offer-accept/{1}`,
        DOWNLOAD_RECEIPT: `${LPC_API_URL}/participation/{1}/download`,
        GET_PARTICIPATION_DATA: `${LPC_API_URL}/pools/{1}/participations/{2}`,
        GET_BANNER: `${LPC_API_URL}/pools/{1}/banner?bannerPage={2}`,
        GET_TRUSTPILOT_STATS: `${LPC_API_URL}/trustpilot/stats`,
        GET_INVITES: `${LPC_API_URL}/pools/{1}/invites`,
        UPDATE_PARTICIPATION_PRIVACY: `${LPC_API_URL}/pools/{1}/participations/{2}`,
        GET_PARTICIPANTS_LIST: `${LPC_API_URL}/pools/{1}/participants-list`,
        TOGGLE_POT_IMAGE: `${LPC_API_URL}/pools/{1}/togglePoolImage`,
        REPORT: `${LPC_API_URL}/pools/{1}/report`,
        SEND_REVIVES: `${LPC_API_URL}/pools/{1}/revives`,
        CHECK_USER: `${LPC_API_URL}/pools/{1}/check-user`,
        REFUND_PARTICIPATION: `${LPC_API_URL}/pools/{1}/participants/{2}/refund`,
    },
    ACTIVITIES: {
        GET_ALL_ACTIVITIES: `${LPC_API_URL}/pools/{1}/activities?cursor={2}&pageSize={3}`,
        GET_ALL_ACTIVITIES_BY_PERSON: `${LPC_API_URL}/pools/{1}/activities-by-person?cursor={2}&pageSize={3}&personID={4}`,
        LIKE_ACTIVITY: `${LPC_API_URL}/pools/{1}/activity/{2}/like`,
        UNLIKE_ACTIVITY: `${LPC_API_URL}/pools/{1}/activity/{2}/unlike`,
        DELETE_ACTIVITY: `${LPC_API_URL}/pools/{1}/activity/{2}`,
    },
    GREETING_CARD: {
        GET_GREETING_CARD_INFORMATIONS: `${LPC_API_URL}/pools/{1}/wish-card-infos`,
        ADD_MESSAGE: `${LPC_API_URL}/pools/{1}/wish-card`,
        UPDATE: `${LPC_API_URL}/pools/{1}/wish-card-infos`,
        GET_ALL_MESSAGES: `${LPC_API_URL}/pools/{1}/wish-cards/messages?cursor={2}&pageSize={3}`,
        GET_MY_MESSAGE: `${LPC_API_URL}/pools/{1}/wish-card`,
        GET_MY_MESSAGE_BY_ORDER_ID: `${LPC_API_URL}/pools/{1}/payments/{2}/wish-card`,
        DELETE_MESSAGE: `${LPC_API_URL}/pools/{1}/wish-card/{2}`,
        DELETE_MESSAGE_BY_ORDER_ID: `${LPC_API_URL}/pools/{1}/payments/{2}/wish-card`,
    },
    FILE: {
        UPLOAD_FILE: `${LPC_API_URL}/files/{1}`,
    },
    CONTACT: {
        CONTACT: `${LPC_API_URL}/send-contact`,
    },
    DASHBOARD: {
        GET_POTS: `${LPC_API_URL}/dashboard/pools`,
        SPENDING: `${LPC_API_URL}/dashboard/spending`,
        GET_COMMUNICATION_PREFERENCES: `${LPC_API_URL}/dashboard/communication-preference`,
        GET_DOCUMENTS: `${LPC_API_URL}/account/documents`,
        UPDATE_NOTIFICATIONS_PREFERENCES: `${LPC_API_URL}/dashboard/notifications-preference`,
        UPDATE_NEWSLETTER_PREFERENCES: `${LPC_API_URL}/dashboard/newsletter-preference`,
        DELETE_IBAN: `${LPC_API_URL}/ibans/{1}`,
        GET_NOTIFICATIONS: `${LPC_API_URL}/dashboard/notifications?cursor={1}&pageSize={2}`,
        UPDATE_EMAIL: `${LPC_API_URL}/dashboard/account/update-email`,
        UPDATE_PASSWORD: `${LPC_API_URL}/dashboard/account/update-password`,
        GET_CONTRIBUTIONS: `${LPC_API_URL}/dashboard/contributions`,
        HIDE_CONTRIBUTION_AMOUNT: `${LPC_API_URL}/dashboard/contribution/{1}`,
        UPDATE_PARTICIPANT_NAME: `${LPC_API_URL}/invites/{1}/change-first-last-name`,
    },
    CADO_CARD: {
        GET_ALL: `${LPC_API_URL}/pools/{1}/cado-cards`,
        GET: `${LPC_API_URL}/pools/{1}/cado-cards/{2}`,
        CREDIT: `${LPC_API_URL}/pools/{1}/cado-cards/{2}`,
        CREATE: `${LPC_API_URL}/pools/{1}/cado-cards`,
        GET_CITIES: `${LPC_API_URL}/address/{1}`,
    },
    GIFT_CARD: {
        GET_GIFT_CARD: `${LPC_API_URL}/pools/{1}/gift-card/{2}`,
        DOWNLOAD: `${LPC_API_URL}/pools/{1}/gift-card/{2}/download`,
    },
    KYC: {
        VALIDATE: `${LPC_API_URL}/kyc-remediation/validate`,
    },
    TUTORIAL: `${LPC_API_URL}/first-time-on-potboard`,
    ALERTS: {
        GET: `${LPC_API_URL}/global-alert.json`,
        COMPETITION_GAME: `${LPC_API_URL}/contests`,
        SET_COMPETITION_GAME: `${LPC_API_URL}/contests/{1}/add-participation`,
    },
    HELP_CENTER: {
        TITLE_SEARCH: `${LPC_API_URL}/help-center/search?keywords={1}&limit={2}`,
        KEYWORDS_SEARCH: `${LPC_API_URL}/help-center/keywords-search?keywords={1}&limit={2}&limitThematics={3}`,
        THEMATICS: `${LPC_API_URL}/help-center/mini-thematics?limit={1}`,
        THEMATICS_DETAILS: `${LPC_API_URL}/help-center/thematics-details?limit={1}`,
        REACTION: `${LPC_API_URL}/help-center/reaction?questionID={1}&action={2}`,
        CONTACT: `${LPC_API_URL}/send-contact`,
        ZENDESK: `${LPC_API_URL}/create-zendesk-ticket`,
    },
    OSFLM: {
        VALIDATE_USER_INFO: `${LPC_API_URL}/osflm-check-validity`,
        VALIDATE_FORM: `${LPC_API_URL}/osflm-submit-participation`,
    },
    SURVEY: {
        SEND_SURVEY: `${LPC_API_URL}/surveys`,
    },
    CONTEST: {
        GET_ACTIVE_CONTEST: `${LPC_API_URL}/get-active-contest-banner`,
    },
};

export default ApiLpc;
