export const setItemToStorage = (storage: string, item, value) => {
    if (typeof window !== 'undefined') {
        if (storage === 'local') {
            return localStorage && localStorage.setItem(item, JSON.stringify(value));
        }
        if (storage === 'session') {
            return sessionStorage && sessionStorage.setItem(item, JSON.stringify(value));
        }
    }
};

export const getItemFromStorage = (storage: string, item) => {
    if (typeof window !== 'undefined') {
        if (storage === 'local') {
            const jsonValue = localStorage && localStorage.getItem(item);
            return jsonValue && JSON.parse(jsonValue);
        }
        if (storage === 'session') {
            const jsonValue = sessionStorage && sessionStorage.getItem(item);
            return jsonValue && JSON.parse(jsonValue);
        }
    }
};

export const clearItemFromStorage = (storage: string, item) => {
    if (typeof window !== 'undefined') {
        if (storage === 'local') {
            localStorage.removeItem(item);
        }
        if (storage === 'session') {
            sessionStorage.removeItem(item);
        }
    }
};

export const clearStorage = (storage: string) => {
    if (typeof window !== 'undefined') {
        if (storage === 'local') {
            localStorage.clear();
        }
        if (storage === 'session') {
            sessionStorage.clear();
        }
    }
};

export default { setItemToStorage, getItemFromStorage, clearItemFromStorage, clearStorage };
