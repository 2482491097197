export const PotCategory = {
    0: 'BIRTHDAY', // 0 Anniversaire
    1: 'DEPARTURE_KITTY', // 1 Pot de départ
    2: 'PARTY_HONEY_NIGHT', // 2 EVG / EVJF
    3: 'ORGANIZATION', //  3 unused
    4: 'COTISATION', // 4 Cotisation
    5: 'CONTRIBUTIONS', // 5 Cotisation
    6: 'BEREAVEMENT', // 6 Obsèques
    7: 'FRIENDSHOLIDAYS', // 7 Week-end & vacances entre amis
    8: 'TRIP', // 8 deprecated
    9: 'HOUSEWARMING', // 9 Pendaison de crémaillère
    10: 'FUNDRAISING', // 10 Collecte de dons
    11: 'OTHER', // 11 Autre
    12: 'COMMON_GIFT', // 12 Cadeaux communs
    13: 'THESIS', // 13 Thèse
    14: 'ANIMALS', // 14 Animal deprecated
    15: 'SNCF', // 15 Sncf
    16: 'NEWYEAR', // 16 Réveillon,
    17: 'SOLIDARY_KITTY', // 17 Solidaire
    18: 'RERPAYMENT_SNCF', // 18 remboursementSncf
    19: 'CORSAIR', // 19
    20: 'VVF_VILLAGES', // 20
    21: 'PACK_TRIBU', // 21
    22: 'PROFESSOR_GIFT', // 22 --- BEGIN MASHAREECOLE
    23: 'BIRTHDAY_MASHAREECOLE', // 23
    24: 'PARENTS_EVENT', // 24
    25: 'GROUPED_PAYMENT', // 25
    26: 'PARENTS_ASSOCIATION', // 26
    27: 'FINANCE_PROJECT', // 27
    28: 'OTHER_MASHAREECOLE', // 28 --- END MASHAREECOLE
    29: 'VVF_VILLAGES_CARTE_CADEAU', // 29 VVF Villages carte Cadeau
    30: 'I_WANTIT', // 30 iwantit
    31: 'GROUPCORNER', // 31
    32: 'EVANEOS', // 32
    33: 'HONEY_MOON', // 33 -- relais chateaux added category
    34: 'RELAISCHATEAULISTES', // 34 -- relais chateaux listes added category
    35: 'EKIDEN_RUN', // 35 EKIDEN - NO LONGER USED
    36: 'BIRTH_REGULAR', // 36 Naissances credit cooperatif
    37: 'WEDDING_REGULAR', // 37 Mariage credit cooperatif
    38: 'SOLIDARY_REGULAR', // 38 Solidaire without
    39: 'SCHOOL_TRIP', // 39 Voyages scolaires APEL
    40: 'CHOCOLAT', // 40 APEL
    41: 'SAPIN', // 41 APEL
    42: 'CONVALESCENCE', // 42 Convalescence CEN
    43: 'BIRTH_GIFT', // 43 Cadeau de naissance CEN
    44: 'WEDDING_PACS_GIFT', // 44 Cadeau de mariage/PACS CEN
    45: 'TEACHER', // 45 nature et découvertes
    46: 'MOTHER', // 46 nature et découvertes
    47: 'FATHER', // 47 nature et découvertes
    48: 'EXAM_SUCCESS', // 48 banque de tahiti
    49: 'BRINGUE', // 49 banque de tahiti
    50: 'NUMWORKS', // 50 numworks
    51: 'GROUPED_ORDER', // 51 // grouped order
    52: 'CULTURAL', // 52 --- Banque nouvelle Calédonie Begin
    53: 'ENTREPRISE', // 53
    54: 'ENVIRONMENT', // 54
    55: 'SPORTS', // 55
    56: 'MACABANE', // 56
    57: 'BABY_SHOWER', // 57
    58: 'FRIENDS_EVENING', // 58  ---  Banque nouvelle Calédonie End
    59: 'CFV_WEDDING', // 59
    60: 'CFV_GIFTS', // 60
    61: 'CFV_PACS', // 61
    62: 'MISTRAL_GROUPED_ORDER', // 62 --- Biscuit Mistral
    63: 'DNC', // 62 --- donsDansNosCoeurs
    64: 'GIFT_BIRTH', // 64
    65: 'NEW_BIRTH', // 65 Naissances
    66: 'WEDDING_PACS', // 66 Mariage PACK
    67: 'TEACHER_GIFT', // 67 Cadeau maîtresse/maître d’école
};
