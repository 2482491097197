import { getUserAvatarSrc } from '@services/utils/UserService';

const UserReducer = (state, action) => {
    switch (action.type) {
        case 'GET_USER_ACCOUNT_START':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_USER_ACCOUNT_SUCCESS':
            return {
                ...state,
                userAccount: {
                    ...action.payload,
                    avatar: getUserAvatarSrc(action.payload),
                },
                isLoading: false,
            };
        case 'GET_USER_ACCOUNT_FAILURE':
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default UserReducer;
