import axios from 'axios';

import { RequestInterceptor } from '@services/http/RequestInterceptor';
import ResponseInterceptor from '@services/http/ResponseInterceptor';
import { getRoute } from '@services/http/Route';

const username = 'lpc';
const password = '123commun';

export const axiosClient = (ctx) => {
    const client = axios.create({
        timeout: 60000,
        proxy: {
            host: 'localhost',
            port: 3000,
        },
        auth: {
            username: username,
            password: password,
        },
        headers: ctx?.req.headers?.cookie ? { cookie: ctx.req.headers.cookie } : undefined,
    });

    client.interceptors.request.use(RequestInterceptor(ctx));
    client.interceptors.response.use(ResponseInterceptor.handleSuccess(ctx), ResponseInterceptor.handleError(ctx));

    return client;
};

export const isInternalServerError = (err) => {
    return err && ((err.status >= 500 && err.status < 600) || err.status === 429);
};

export const isNotFoundError = (err) => {
    return err && err.status === 404;
};

export const isForbiddenError = (err) => {
    return err && err.status === 401;
};

export const isRetryError = (err) => {
    return err && err.code === 'ERROR_RETRY_PAYMENT';
};

export const getUrl = function (...args) {
    if (!args || args.length === 0) {
        return;
    }
    let url = args[0];
    for (let i = 1; i < args.length; i++) {
        url = url.replace(new RegExp('\\{' + i + '\\}', 'g'), args[i]);
    }
    return url;
};

export const redirectToCustomUrl = (customUrl: string, ctx) => {
    ctx.res.writeHead(302, {
        Location: getRoute(customUrl),
    });

    ctx.res.end();
};
