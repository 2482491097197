import React, { useCallback, useReducer } from 'react';

import potService from '@services/domain/Lpc/PotService';

import PotContext from './PotContext';
import potReducer from './PotReducer';

type PotProviderProps = {
    children: React.ReactNode;
};

const PotProvider = ({ children }: PotProviderProps) => {
    const initialState = {
        updatedPot: {},
        error: null,
        isLoading: false,
    };

    const [state, dispatch] = useReducer(potReducer, initialState);

    const getUpdatedPot = useCallback(async (potRef: string) => {
        try {
            dispatch({ type: 'GET_POT_START' });
            const data = await potService.getPot(potRef);
            dispatch({ type: 'GET_POT_SUCCESS', payload: data });
        } catch (err) {
            dispatch({ type: 'GET_POT_FAILURE', payload: err.message });
        }
    }, []);

    const setUpdatedPot = (pot) => {
        state.updatedPot = pot;
    };

    return (
        <PotContext.Provider
            value={{
                updatedPot: state.updatedPot,
                error: state.error,
                isLoading: state.isLoading,
                getUpdatedPot,
                setUpdatedPot,
            }}
        >
            {children}
        </PotContext.Provider>
    );
};

export default PotProvider;
