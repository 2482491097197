import React, { useCallback, useReducer } from 'react';

import AuthService from '@services/domain/Lpc/AuthService';

import UserContext from './UserContext';
import userReducer from './UserReducer';

type UserProviderProps = {
    children: React.ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
    const initialState = {
        userAccount: {},
        error: null,
        isLoading: true,
    };

    const [state, dispatch] = useReducer(userReducer, initialState);

    const getUserAccount = useCallback(async () => {
        try {
            dispatch({ type: 'GET_USER_ACCOUNT_START' });
            const data = await AuthService.getUserAccount();
            dispatch({ type: 'GET_USER_ACCOUNT_SUCCESS', payload: data.data });
        } catch (err) {
            dispatch({ type: 'GET_USER_ACCOUNT_FAILURE', payload: err.message });
        }
    }, []);

    return (
        <UserContext.Provider
            value={{
                userAccount: state.userAccount,
                error: state.error,
                isLoading: state.isLoading,
                getUserAccount,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
