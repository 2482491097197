export const EXT_LINK = {
    APP_STORE: 'https://apps.apple.com/fr/app/le-pot-commun/id1119208961',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=fr.lepotcommun.lpc',
    CITYVENT: 'https://www.cityvent.com/',
    BUSINESS: 'https://business.lepotcommun.fr/',
    FACEBOOK: 'https://www.facebook.com/Lepotcommun.fr',
    INSTAGRAM: 'https://www.instagram.com/le_pot_commun.fr/',
    TWITTER: 'https://twitter.com/lepotcommun',
    LINKEDIN: 'https://www.linkedin.com/company/le-pot-commun/?originalSubdomain=fr',
    TIKTOK: 'https://www.tiktok.com/@lepotcommun',
    TRUSPILOT: 'https://fr.trustpilot.com/review/lepotcommun.fr',
    CADO_STORE: 'https://app.cadostore.com/retailers-all-cado',
    CADO_STORE_ACTIVATE: 'https://app.cadostore.com/activation',
    CADO_STORE_CONTACT: 'https://cadostore.com/contactez-nous',
    MPP_CODE_REDIRECT: 'https://app.monpetitplacement.fr',
    PRESS: 'https://newsroom.lepotcommun.fr/',
};
