const PotReducer = (state, action) => {
    switch (action.type) {
        case 'GET_POT_START':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_POT_SUCCESS':
            return {
                ...state,
                updatedPot: action.payload,
                isLoading: false,
            };
        case 'GET_POT_FAILURE':
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default PotReducer;
