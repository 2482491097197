import _ from 'lodash';

import { PotCategory } from '@models/PotModel/potCategoryModel';
import { encodeImage } from '@services/utils/StringService';

import commonJson from '../../../public/front-static/static/locales/fr/common.json';

export const getUserAvatarSrc = (user) => {
    if (user.promoCode) {
        return {
            src: `/front-static/icons/logo/lpc.svg`,
            isEmoji: true,
        };
    }
    const avatar = user.avatar;
    if (!avatar && !(user.urlProfileImg || user.urlProfileImgPE)) {
        return {
            src: `/front-static/icons/avatar/OCTOPUS.svg`,
            isEmoji: true,
        };
    }
    return avatar
        ? {
              src: `/front-static/icons/avatar/${avatar}.svg`,
              isEmoji: true,
          }
        : {
              src: user.urlProfileImg || user.urlProfileImgPE,
              isEmoji: false,
          };
};

export const getAvatarSrcForPdf = (user) => {
    const avatar = user.avatar;
    if (!avatar && !(user.urlProfileImg || user.urlProfileImgPE)) {
        return {
            src: encodeImage(`/front-static/icons/avatar/png/OCTOPUS.png`),
            isEmoji: true,
        };
    }
    return avatar
        ? {
              src: encodeImage(`/front-static/icons/avatar/png/${avatar}.png`),
              isEmoji: true,
          }
        : {
              src: encodeImage(user.urlProfileImg) || encodeImage(user.urlProfileImgPE),
              isEmoji: false,
          };
};

export const getCategoryIconSrc = (category) => {
    switch (category) {
        case 'PARTY_HONEY_NIGHT':
            return `/front-static/icons/pot/categories/PARTY_HONEY_NIGHT.gif`;
        case 'WEDDING_PACS':
            return `/front-static/icons/pot/categories/WEDDING_PACS.gif`;
        case 'WEDDING_REGULAR':
            return `/front-static/icons/pot/categories/WEDDING_PACS_GIFT.svg`;
        case 'MPP':
            return `/front-static/icons/pot/categories/${category}.png`;
        case 'TEACHER_GIFT':
        case 'SOLIDARITY_POOL':
            return `/front-static/icons/pot/categories/${category}.png`;
        default:
            return `/front-static/icons/pot/categories/${category}.svg`;
    }
};

export const isSupportedCategory = (category) => {
    return Object.keys(commonJson.select['pot-category']).includes(category);
};
export const getCategoryIndex = (category) => {
    return _.invert(PotCategory)[category];
};

export default {
    getUserAvatarSrc,
    getAvatarSrcForPdf,
    getCategoryIconSrc,
    isSupportedCategory,
    getCategoryIndex,
};
