import React from 'react';

import { useRouter } from 'next/router';

import Text from '@components/common/Text/Text';
import { getRoute, ROUTE } from '@services/http/Route';

import css from './AlertBanner.module.scss';

export type AlertBannerProps = {
    message: string;
    customClass?: string;
};
const AlertBanner = ({ message, customClass }: AlertBannerProps) => {
    const router = useRouter();

    const alertStyle = () => {
        if (!router) {
            return '';
        }
        let className = css.alert__banner + ' ' + customClass || '';
        router.asPath.endsWith(getRoute(ROUTE.LPC.AUTH.SIGN_IN)) && (className += ' ' + css.sign_in_alert_banner);
        router.asPath.endsWith(getRoute(ROUTE.LPC.POT.SPENDING)) && (className += ' ' + css.spending_alert_banner);
        router.asPath.endsWith(getRoute(ROUTE.LPC.POT.SPENDING_PARTNER)) &&
            router.asPath.includes(getRoute(ROUTE.LPC.POT.POT)) &&
            (className += ' ' + css.spending_alert_banner);
        router.asPath.includes(getRoute(ROUTE.LPC.POT.SPENDING_PARTNER_E_CARD)) &&
            (className += ' ' + css.spending_alert_banner);
        return className;
    };

    return (
        <>
            <div className={alertStyle()}>
                <Text
                    htmlText={message}
                    html
                    customClass={`${css.sub_category}`}
                    variant={'caption_01'}
                    color={'ui-error'}
                />
            </div>
        </>
    );
};

export default AlertBanner;
