// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RequestInterceptor = (ctx) => {
    return (config) => {
        // only add user-agent header when the call is between the Next Server and our backend
        if (ctx) {
            ctx.config = config;
            config.headers['User-agent'] = 'axios-lpc-frontend';
        }
        config.headers['Origin-App'] = 'frontend';
        return config;
    };
};
