import imageToBase64 from 'image-to-base64/browser';

export const getFirstLetterUpperCase = (string) => {
    return string.charAt(0).toUpperCase();
};

export const getFirstWordFirstLetterUpperCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const lastUrlSegment = (url) => {
    return url.substr(url.lastIndexOf('/') + 1);
};

export const isLpcUrl = (url): boolean => {
    if (!url) {
        return false;
    }
    const domain = new URL(url);
    const hostName = domain.hostname.substring(domain.hostname.indexOf('.') + 1);
    return hostName === process.env.BASE_URL;
};

export const htmlToText = (html) => {
    if (!html) {
        return;
    }
    return html.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ');
};

export const getCustomUrl = (url) => {
    if (url.search('-') === -1) {
        return '';
    }
    return url.substr(url.lastIndexOf('-') + 1);
};

export const getUrlWithoutParams = (url) => {
    if (url.indexOf('?') === -1) {
        return url;
    }
    return url.substring(0, url.indexOf('?'));
};

export const getAbsoluteUrl = (url) => {
    if (url.search('-') === -1) {
        return url;
    }
    return url.split('-')[0];
};

export const checkValidEmail = (email) => {
    const check = email.match(/^\S+@\S+$/);
    if (check) {
        return email;
    }
};

export const formatCurrency = (amount: number) => {
    if (!amount) {
        amount = 0;
    }
    if (typeof amount === 'string') {
        const amountToNumber = parseFloat(amount);
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amountToNumber);
    } else {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount);
    }
};

export const formatIban = (iban: string) => {
    let newIban = '';
    if (iban) {
        iban = iban.replace(/\s+/g, '');
        for (let i = 0; i < iban.length; i += 4) {
            newIban = newIban + iban.slice(i, i + 4) + ' ';
        }
        newIban = newIban.trim();
    }
    return newIban;
};

export const encodeImage = (img) => {
    return imageToBase64(img)
        .then((res) => `data:image;base64,${res}`)
        .catch((error) => error);
};

export const truncate = (str: string, n: number) => {
    return n > 0 && str.length > n ? str.substr(0, n - 1) : str;
};

export const replaceValueInString = (str: string, searchValue: string, replaceValue: string) => {
    if (!str) {
        return '';
    }
    return str.replace(new RegExp(searchValue, 'g'), replaceValue);
};

export const copyTextToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export default {
    getFirstLetterUpperCase,
    getFirstWordFirstLetterUpperCase,
    lastUrlSegment,
    htmlToText,
    getCustomUrl,
    checkValidEmail,
    formatCurrency,
    formatIban,
    getUrlWithoutParams,
    encodeImage,
    truncate,
    replaceValueInString,
    isLpcUrl,
    copyTextToClipboard,
};
