import { CreatePotType, potType } from '@propTypes/potTypes';
import ApiLpc from '@services/http/ApiLpc';
import { axiosClient, getUrl } from '@services/http/Http';

const createPot = (body: CreatePotType, ctx?: string) =>
    axiosClient(ctx).post(ApiLpc.POT.CREATE_POT, JSON.stringify(body));

const getPot = (potRef: string, ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.POT.GET_POT, potRef))
        .then((res) => {
            return res.data;
        });

const checkPotExist = (potRef: string, ctx?: string) =>
    axiosClient(ctx).get(getUrl(ApiLpc.POT.CHECK_POT_EXIST, potRef));

const deletePot = (potRef: string, refund: boolean, ctx?: string) =>
    axiosClient(ctx)
        .delete(getUrl(ApiLpc.POT.DELETE_POT, potRef, refund))
        .then((res) => {
            return res.data;
        });

const updatePotTitle = (potRef: string, title: string, ctx?: string) =>
    axiosClient(ctx).put(getUrl(ApiLpc.POT.UPDATE_TITLE, potRef), JSON.stringify(title));

const updatePotMppParent = (potRef: string, body: { isOrganiserParent: boolean }, ctx?: string) =>
    axiosClient(ctx).put(getUrl(ApiLpc.POT.UPDATE_MPP_PARENT, potRef), JSON.stringify(body));

const getPotSettings = (potRef: string, ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.POT.GET_POT_SETTINGS, potRef))
        .then((res) => res.data);

const getPotHistory = (potRef: string, ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.POT.GET_POT_HISTORY, potRef))
        .then((res) => res.data);

const getCadoCard = (potRef: string, ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.POT.GET_POT_CADO_CARD, potRef))
        .then((res) => res.data);

const updatePotSettingsGeneral = (
    potRef: string,
    body: { askForMessage?: boolean; statusPO?: string; customUrlSuffixPO?: string },
    ctx?: string,
) =>
    axiosClient(ctx)
        .put(getUrl(ApiLpc.POT.UPDATE_POT_SETTINGS_GENERAL, potRef), JSON.stringify(body))
        .then((res) => res.data);

const updatePotSettingsParticipations = (
    potRef: string,
    body: { contributionType: string; contributionAmount?: number },
    ctx?: string,
) => axiosClient(ctx).put(getUrl(ApiLpc.POT.UPDATE_POT_SETTINGS_PARTICIPATIONS, potRef), JSON.stringify(body));

const updatePotSettingsConfidentiality = (
    potRef: string,
    body: {
        showTotalContribution: boolean;
        showParticipantName: boolean;
        showContributionAmount: boolean;
        showParticipantsList: boolean;
        askParticipantsForAddress: boolean;
    },
    ctx?: string,
) => axiosClient(ctx).put(getUrl(ApiLpc.POT.UPDATE_POT_SETTINGS_CONFIDENTIALITY, potRef), JSON.stringify(body));

const offerPot = (
    potRef: string,
    body: {
        email: string;
        firstName: string;
        lastName: string;
        message: string;
        title: string;
        signature: string;
    },
    ctx?: string,
) => axiosClient(ctx).post(getUrl(ApiLpc.POT.OFFER_POT, potRef), JSON.stringify(body));

const cancelOfferPot = (potRef: string, ctx?: string) =>
    axiosClient(ctx).delete(getUrl(ApiLpc.POT.CANCEL_OFFER_POT, potRef));

const uploadPotImage = (potRef: string, image: string, ctx?: string) =>
    axiosClient(ctx)
        .post(getUrl(ApiLpc.POT.UPLOAD_POT_IMAGE, potRef), JSON.stringify(image))
        .then((res) => res.data.image);

const removePotImage = (potRef: string, ctx?: string) =>
    axiosClient(ctx).delete(getUrl(ApiLpc.POT.REMOVE_POT_IMAGE, potRef));

const updatePot = (potRef: string, body: { category: string; eventDate: string }, ctx?: string) =>
    axiosClient(ctx).put(getUrl(ApiLpc.POT.UPDATE_POT, potRef), JSON.stringify(body));

const sendInvites = (potRef: string, body: { message: string; emails: string[]; reminder?: string }, ctx?: string) =>
    axiosClient(ctx).post(getUrl(ApiLpc.POT.SEND_INVITES, potRef), JSON.stringify(body));

const getEmailFromXls = (body, ctx?: string) =>
    axiosClient(ctx).post(`${ApiLpc.POT.GET_EMAIL_FROM_XLS}`, body, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

const contactOrganiser = (potRef: string, body: { email: string; message: string }, ctx?: string) =>
    axiosClient(ctx).post(getUrl(ApiLpc.POT.CONTACT_ORGANISER, potRef), JSON.stringify(body));

const updateOrganiser = (potRef: string, body: { organiser: string }, ctx?: string) =>
    axiosClient(ctx).post(getUrl(ApiLpc.POT.UPDATE_ORGANISER, potRef), JSON.stringify(body));

const thankParticipants = (potRef: string, body: { message: string }, ctx?: string) =>
    axiosClient(ctx).post(getUrl(ApiLpc.POT.THANK_PARTICIPANTS, potRef), JSON.stringify(body));

const generateDescription = (body: { description: string; title: string; category: string }, ctx?: string) =>
    axiosClient(ctx).post(getUrl(ApiLpc.POT.GENERATE_DESCRIPTION), JSON.stringify(body));

const getParticipantsList = (potRef: string, ctx?: string) =>
    axiosClient(ctx).get(getUrl(ApiLpc.POT.GET_PARTICIPANTS_LIST, potRef), {
        responseType: 'blob',
    });

const participate = (
    potRef: string,
    body: {
        firstName: string;
        lastName: string;
        email: string;
        address: string;
        contributionAmount: number | string;
        tipsAmount?: number | string;
        optInRequired: boolean;
        confirmNewsletter: boolean;
    },
    ctx?: string,
) =>
    axiosClient(ctx)
        .post(getUrl(ApiLpc.POT.PARTICIPATE, potRef), JSON.stringify(body))
        .then((res) => res.data);

const refundParticipant = (potRef: string, orderID: string, ctx?: string) =>
    axiosClient(ctx).post(getUrl(ApiLpc.POT.REFUND_PARTICIPATION, potRef, orderID));

const getAllParticipants = (potRef: string, cursor: number, pageSize: number, ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.POT.GET_ALL_PARTICIPANTS, potRef, cursor, pageSize))
        .then((res) => res.data);

const getIbans = (ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.POT.GET_IBANS))
        .then((res) => res.data);

const addIban = (
    body: {
        firstName: string;
        lastName: string;
        bic: string;
        iban: string;
        password: string;
    },
    ctx?: string,
) =>
    axiosClient(ctx)
        .put(getUrl(ApiLpc.POT.ADD_IBAN), body)
        .then((res) => res.data);

const createTransfer = (
    potRef: string,
    body: {
        uniqIB: string;
        amount: number;
        collectAll: boolean;
        password: string;
    },
    ctx?: string,
) =>
    axiosClient(ctx)
        .post(getUrl(ApiLpc.POT.CREATE_TRANSFER, potRef), body)
        .then((res) => res.data);

const getOfferedPot = (uniqUP: string, ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.POT.GET_OFFERED_POT, uniqUP))
        .then((res) => res.data);

const acceptOfferedPot = (uniqUP: string, ctx?: string) =>
    axiosClient(ctx).post(getUrl(ApiLpc.POT.ACCEPT_OFFERED_POT, uniqUP));

const getPaymentResponse = (orderId: string, ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.POT.GET_PAYMENT, orderId))
        .then((res) => res.data);

const finalizePayment = (uniqPO: string, orderId: string, ctx?: string) =>
    axiosClient(ctx)
        .post(getUrl(ApiLpc.POT.FINALIZE_PAYMENT, uniqPO), orderId)
        .then((res) => res.data);

const saveDalenysBlockedIp = (orderId: string, ctx?: string) =>
    axiosClient(ctx)
        .post(getUrl(ApiLpc.POT.SAVE_DALENYS_BLOCKED_IP, orderId), orderId)
        .then((res) => res.data);

const downloadReceipt = (orderId: string, ctx?: string) =>
    axiosClient(ctx).get(getUrl(ApiLpc.POT.DOWNLOAD_RECEIPT, orderId), {
        responseType: 'blob',
    });

const getParticipationData = (uniqPO: string, orderId: string, ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.POT.GET_PARTICIPATION_DATA, uniqPO, orderId))
        .then((res) => res.data);

const getBanner = (uniqPO: string, bannerPage: string, ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.POT.GET_BANNER, uniqPO, bannerPage))
        .then((res) => res.data);
const getTrustPilotStats = (ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.POT.GET_TRUSTPILOT_STATS))
        .then((res) => res.data);

const getInvites = (uniqPO: string, ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.POT.GET_INVITES, uniqPO))
        .then((res) => res.data);

const updateParticipationPrivacy = (
    uniqPO: string,
    orderId: string,
    body: { hideName: boolean; hideContribution: boolean },
    ctx?: string,
) =>
    axiosClient(ctx)
        .put(getUrl(ApiLpc.POT.UPDATE_PARTICIPATION_PRIVACY, uniqPO, orderId), JSON.stringify(body))
        .then((res) => res.data);

const togglePoolImage = (uniqPO: string, ctx?: string) =>
    axiosClient(ctx).post(getUrl(ApiLpc.POT.TOGGLE_POT_IMAGE, uniqPO));

const getCommission = (pot: potType): number => {
    if (pot.commissionType === 'COMMISSION_IN' || !pot.commissionIncluded) {
        return 0;
    } else {
        return pot.collectedAmount >= pot.comOutFloor
            ? pot.comOutMid
            : pot.collectedAmount <= pot.comOutFirstFloor
              ? pot.comOutMin
              : pot.comOutMax;
    }
};

const reportPot = (potRef: string, request: { selectedOption: number; message: string }, ctx?: string) =>
    axiosClient(ctx).post(getUrl(ApiLpc.POT.REPORT, potRef), JSON.stringify(request));

const sendRevives = (potRef: string, body: { inviteIDs: number[]; message: string }, ctx?: string) =>
    axiosClient(ctx).post(getUrl(ApiLpc.POT.SEND_REVIVES, potRef), JSON.stringify(body));

const checkUser = (potRef: string, ctx?: string) => axiosClient(ctx).get(getUrl(ApiLpc.POT.CHECK_USER, potRef));

const potService = {
    createPot,
    getPot,
    checkPotExist,
    deletePot,
    updatePotTitle,
    getPotSettings,
    updatePotSettingsGeneral,
    updatePotSettingsParticipations,
    updatePotSettingsConfidentiality,
    uploadPotImage,
    removePotImage,
    updatePot,
    contactOrganiser,
    sendInvites,
    getEmailFromXls,
    getAllParticipants,
    participate,
    thankParticipants,
    getIbans,
    getBanner,
    getOfferedPot,
    acceptOfferedPot,
    finalizePayment,
    downloadReceipt,
    getParticipationData,
    updateParticipationPrivacy,
    getParticipantsList,
    addIban,
    offerPot,
    createTransfer,
    getPotHistory,
    cancelOfferPot,
    getCommission,
    togglePoolImage,
    refundParticipant,
    reportPot,
    getPaymentResponse,
    getTrustPilotStats,
    getCadoCard,
    sendRevives,
    getInvites,
    updateOrganiser,
    updatePotMppParent,
    generateDescription,
    saveDalenysBlockedIp,
    checkUser,
};

export default potService;
