import { replaceValueInString } from '@services/utils/StringService';

const defaultMessage = 'DEFAULT_ERROR_MESSAGE';

export const ErrorHandler = (ctx, err) => {
    // wrong error format
    if (!err || !err.response || !err.response.data) {
        err = {
            status: err && err.response && err.response.status ? err.response.status : 500,
            code: defaultMessage,
            isDefault: true,
            endpoint: ctx?.config?.url,
            method: ctx?.config?.method,
            error: err,
        };
        if (err.status === 500 || err.status === 400) {
            // Sentry.captureException(err);
        }
        return Promise.reject(err);
    }

    // redirection
    if (err.response.status === 302) {
        ctx.res.writeHead(302, {
            Location: err.response.data,
        });
        ctx.res.end();
        return;
    }

    // error handling
    const status = err.response.status;
    err = err.response.data;
    if (err.code === 'CONSTRAINT_EXCEPTION') {
        if (err.errors?.length) {
            err.code = replaceValueInString(err.errors[0].errorMessageKey?.toUpperCase(), '-', '_');
        } else {
            err.isDefault = true;
            err.code = defaultMessage;
        }
    }

    if (!err.code) {
        if (typeof err === 'string') {
            err = { isDefault: true, code: defaultMessage, status: status, error: err };
        } else {
            err.isDefault = true;
            err.code = defaultMessage;
        }
    }
    err.endpoint = ctx?.config?.url;
    err.method = ctx?.config?.method;
    return Promise.reject(err);
};
