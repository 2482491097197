import { AlertCategorie, AllPages } from '@models/BannerModel/BannerPagesModel';
import ApiLpc from '@services/http/ApiLpc';
import { axiosClient, getUrl } from '@services/http/Http';
import { getRoute } from '@services/http/Route';

const getCompetitionGame = async (uniqGame: any, ctx?: string) =>
    axiosClient(ctx).get(getRoute(ApiLpc.ALERTS.COMPETITION_GAME, uniqGame));

const setCompetitionGame = (body: any, ctx?: string) =>
    axiosClient(ctx).post(getUrl(ApiLpc.ALERTS.SET_COMPETITION_GAME, body.contestID), JSON.stringify(body));

const getGlobalAlerts = async (ctx?: string) => {
    try {
        const response = await axiosClient(ctx).get(ApiLpc.ALERTS.GET);
        return response.data;
    } catch (error) {
        // Handle the error here, e.g., log it or show a user-friendly message
        // eslint-disable-next-line no-console
        console.error('An error occurred:', error);
        throw error; // Rethrow the error to let the caller handle it
    }
};
const getActiveContestBanner = (ctx?: string) => axiosClient(ctx).get(ApiLpc.CONTEST.GET_ACTIVE_CONTEST);

const checkShownPossibility = (data, pages) => {
    const check: boolean = false;
    if (checkAllPagesShownPossibility(data)) {
        return check;
    }
    if (data?.categories?.includes(AlertCategorie)) {
        return data.pages.includes(pages);
    }
    return check;
};
const checkAllPagesShownPossibility = (data) => {
    const check: boolean = false;
    if (data?.categories?.includes(AlertCategorie)) {
        return data.pages.includes(AllPages);
    }
    return check;
};

const AlertsService = {
    getGlobalAlerts,
    setCompetitionGame,
    getCompetitionGame,
    checkShownPossibility,
    checkAllPagesShownPossibility,
    getActiveContestBanner,
};
export default AlertsService;
