/**
 * ResponseHandler
 * @module Services/http/ResponseHandler
 * @description Handlers a http response
 */

import { ErrorHandler } from './ErrorHandler';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleSuccess = (ctx) => (response) => {
    // TODO handle response
    return response;
};

const handleError = (ctx) => (error) => {
    return ErrorHandler(ctx, error);
};

const ResponseInterceptor = { handleError, handleSuccess };

export default ResponseInterceptor;
