export const BannerPages = {
    PAYMENT_PAGE_BLOC_1: 'Page de paiement bloc 1',
    PAYMENT_PAGE_BLOC_2: 'Page de paiement bloc 2',
};

export const AlertCategorie = 'Le pot commun';
export const AllPages = 'All Pages';
export const DashboardPageAlert = 'Page Dashboard';
export const PotboardPage = 'Page Potboard';
export const SpendPotPage = 'Page Dépenses pot';
export const HomePageAlert = 'Page Homepage';
