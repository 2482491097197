import React, { useEffect, useState } from 'react';

import 'nprogress/nprogress.css';
import '@assets/styles/styles';
import '@assets/styles/mains.scss';
import '../../node_modules/react-phone-number-input/style.css';
import { TolgeeProvider, useTolgeeSSR } from '@tolgee/react';
import { DevTools, FormatSimple, Tolgee } from '@tolgee/web';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import { ToastProvider } from 'react-toast-notifications';

import AlertBanner from '@components/common/AlertBanner/AlertBanner';
import Toast from '@components/common/ToastNotifications/Toast/Toast';
import ToastContainer from '@components/common/ToastNotifications/ToastContainer/ToastContainer';
import { GLOBAL_ALERTS_RETRY_TIME } from '@components/lpc/Constants';
import PotProvider from '@components/lpc/Context/Pot/PotProvider';
import UserProvider from '@components/lpc/Context/User/UserProvider';
import AlertsService from '@services/domain/Lpc/AlertsService';
import { AnalyticsService } from '@services/utils/AnalyticsService';
import { addMinutes } from '@services/utils/DateService';

import frBabyShowerLanding from '../../public/front-static/static/i18n/baby-shower-landing/fr.json';
import frBereavementLanding from '../../public/front-static/static/i18n/bereavement-landing/fr.json';
import frCommon from '../../public/front-static/static/i18n/common/fr.json';
import frCommonGiftLanding from '../../public/front-static/static/i18n/common-gift-landing/fr.json';
import frDashboardAuthentication from '../../public/front-static/static/i18n/dashboard-authentication/fr.json';
import frDashboardHeader from '../../public/front-static/static/i18n/dashboard-header/fr.json';
import frDashboardInformations from '../../public/front-static/static/i18n/dashboard-informations/fr.json';
import frDashboardPartners from '../../public/front-static/static/i18n/dashboard-partners/fr.json';
import frDepartureKittyLanding from '../../public/front-static/static/i18n/departure-kitty-landing/fr.json';
import frFriendsHolidaysLanding from '../../public/front-static/static/i18n/friendsholidays-landing/fr.json';
import frHelpCenter from '../../public/front-static/static/i18n/help-center/fr.json';
import frHousewarmingLanding from '../../public/front-static/static/i18n/housewarming-landing/fr.json';
import frLpcAuthentication from '../../public/front-static/static/i18n/lpc-authentication/fr.json';
import frLpcBankTransfer from '../../public/front-static/static/i18n/lpc-bank-transfer/fr.json';
import frLpcCollectSeo from '../../public/front-static/static/i18n/lpc-collect-seo/fr.json';
import frLpcContact from '../../public/front-static/static/i18n/lpc-contact/fr.json';
import frLpcCookie from '../../public/front-static/static/i18n/lpc-cookie/fr.json';
import frLpcCreatePot from '../../public/front-static/static/i18n/lpc-create-pot/fr.json';
import frLpcDashboard from '../../public/front-static/static/i18n/lpc-dashboard/fr.json';
import frLpcDocuments from '../../public/front-static/static/i18n/lpc-documents/fr.json';
import frLpcDoubleAuthentication from '../../public/front-static/static/i18n/lpc-double-authentication/fr.json';
import frLpcExpenses from '../../public/front-static/static/i18n/lpc-expenses/fr.json';
import frLpcFakeCards from '../../public/front-static/static/i18n/lpc-fake-cards/fr.json';
import frLpcFooter from '../../public/front-static/static/i18n/lpc-footer/fr.json';
import frLpcGift from '../../public/front-static/static/i18n/lpc-gift/fr.json';
import frLpcGiftCard from '../../public/front-static/static/i18n/lpc-gift-card/fr.json';
import frLpcGreetingCard from '../../public/front-static/static/i18n/lpc-greeting-card/fr.json';
import frHome from '../../public/front-static/static/i18n/lpc-home/fr.json';
import frLpcNavbar from '../../public/front-static/static/i18n/lpc-navbar/fr.json';
import frLpcOsflm from '../../public/front-static/static/i18n/lpc-osflm/fr.json';
import frLpcPartnerDetails from '../../public/front-static/static/i18n/lpc-partner-details/fr.json';
import frLpcPartnersNetwork from '../../public/front-static/static/i18n/lpc-partners-network/fr.json';
import frLpcPayWithPot from '../../public/front-static/static/i18n/lpc-pay-with-pot/fr.json';
import frLpcPot from '../../public/front-static/static/i18n/lpc-pot/fr.json';
import frLpcPotBankTransfer from '../../public/front-static/static/i18n/lpc-pot-bank-transfer/fr.json';
import frLpcPotECard from '../../public/front-static/static/i18n/lpc-pot-e-card/fr.json';
import frLpcPotGiftCard from '../../public/front-static/static/i18n/lpc-pot-gift-card/fr.json';
import frLpcPotHistory from '../../public/front-static/static/i18n/lpc-pot-history/fr.json';
import frLpcPotMppSpending from '../../public/front-static/static/i18n/lpc-pot-mpp-spending/fr.json';
import frLpcPotOffer from '../../public/front-static/static/i18n/lpc-pot-offer/fr.json';
import frLpcPotPartnerGiftcard from '../../public/front-static/static/i18n/lpc-pot-partner-giftcard/fr.json';
import frLpcPotPayment from '../../public/front-static/static/i18n/lpc-pot-payment/fr.json';
import frLpcPotSpending from '../../public/front-static/static/i18n/lpc-pot-spending/fr.json';
import frLpcPrivacyPolicy from '../../public/front-static/static/i18n/lpc-privacy-policy/fr.json';
import frLpcReport from '../../public/front-static/static/i18n/lpc-report/fr.json';
import frLpcSecurePayment from '../../public/front-static/static/i18n/lpc-secure-payment/fr.json';
import frLpcTerms from '../../public/front-static/static/i18n/lpc-terms/fr.json';
import frNewBirthLanding from '../../public/front-static/static/i18n/new-birth-landing/fr.json';
import frOdvp2022 from '../../public/front-static/static/i18n/odvp-2022/fr.json';
import frPartyHoneyNightLanding from '../../public/front-static/static/i18n/party-honey-night-landing/fr.json';
import frSolidarityPoolLanding from '../../public/front-static/static/i18n/solidarity-pool-landing/fr.json';
import frTeacherGiftLanding from '../../public/front-static/static/i18n/teacher-gift-landing/fr.json';
import frWeddingPacsLanding from '../../public/front-static/static/i18n/wedding-pacs-landing/fr.json';

NProgress.configure({ showSpinner: false, speed: 200 });

Router.events.on('routeChangeStart', () => {
    NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
    NProgress.done();
});

Router.events.on('routeChangeError', () => {
    NProgress.done();
});

const namespaces = ['common', 'lpc-home'];

const tolgee = Tolgee()
    .use(DevTools())
    .use(FormatSimple())
    .init({
        defaultLanguage: 'fr',
        language: 'fr',
        defaultNs: 'common',
        // for dev mode
        apiKey: process.env.NEXT_PUBLIC_TOLGEE_API_KEY,
        apiUrl: process.env.NEXT_PUBLIC_TOLGEE_API_URL,
        // for non dev mode (int / staging / production)
        staticData: {
            'fr:lpc-authentication': frLpcAuthentication,
            'fr:common': frCommon,
            'fr:dashboard-authentication': frDashboardAuthentication,
            'fr:lpc-double-authentication': frLpcDoubleAuthentication,
            'fr:dashboard-header': frDashboardHeader,
            'fr:dashboard-informations': frDashboardInformations,
            'fr:dashboard-partners': frDashboardPartners,
            'fr:help-center': frHelpCenter,
            'fr:lpc-bank-transfer': frLpcBankTransfer,
            'fr:lpc-collect-seo': frLpcCollectSeo,
            'fr:lpc-contact': frLpcContact,
            'fr:lpc-cookie': frLpcCookie,
            'fr:lpc-create-pot': frLpcCreatePot,
            'fr:lpc-dashboard': frLpcDashboard,
            'fr:lpc-expenses': frLpcExpenses,
            'fr:lpc-footer': frLpcFooter,
            'fr:lpc-gift': frLpcGift,
            'fr:lpc-greeting-card': frLpcGreetingCard,
            'fr:lpc-home': frHome,
            'fr:lpc-navbar': frLpcNavbar,
            'fr:lpc-osflm': frLpcOsflm,
            'fr:lpc-partner-details': frLpcPartnerDetails,
            'fr:lpc-partners-network': frLpcPartnersNetwork,
            'fr:lpc-pay-with-pot': frLpcPayWithPot,
            'fr:lpc-pot': frLpcPot,
            'fr:lpc-pot-bank-transfer': frLpcPotBankTransfer,
            'fr:lpc-pot-e-card': frLpcPotECard,
            'fr:lpc-pot-gift-card': frLpcPotGiftCard,
            'fr:lpc-pot-history': frLpcPotHistory,
            'fr:lpc-pot-offer': frLpcPotOffer,
            'fr:lpc-pot-partner-giftcard': frLpcPotPartnerGiftcard,
            'fr:lpc-pot-payment': frLpcPotPayment,
            'fr:lpc-pot-spending': frLpcPotSpending,
            'fr:lpc-privacy-policy': frLpcPrivacyPolicy,
            'fr:lpc-report': frLpcReport,
            'fr:lpc-secure-payment': frLpcSecurePayment,
            'fr:lpc-terms': frLpcTerms,
            'fr:odvp-2022': frOdvp2022,
            'fr:lpc-pot-mpp-spending': frLpcPotMppSpending,
            'fr:lpc-gift-card': frLpcGiftCard,
            'fr:lpc-documents': frLpcDocuments,
            'fr:lpc-fake-cards': frLpcFakeCards,
            'fr:teacher-gift-landing': frTeacherGiftLanding,
            'fr:solidarity-pool-landing': frSolidarityPoolLanding,
            'fr:new-birth-landing': frNewBirthLanding,
            'fr:departure-kitty-landing': frDepartureKittyLanding,
            'fr:housewarming-landing': frHousewarmingLanding,
            'fr:common-gift-landing': frCommonGiftLanding,
            'fr:baby-shower-landing': frBabyShowerLanding,
            'fr:wedding-pacs-landing': frWeddingPacsLanding,
            'fr:bereavement-landing': frBereavementLanding,
            'fr:friendsholidays-landing': frFriendsHolidaysLanding,
            'fr:party-honey-night-landing': frPartyHoneyNightLanding,
        },
        fallbackNs: namespaces,
    });

const MyApp = ({ Component, pageProps, ...args }: any) => {
    const router = useRouter();
    const [globalAlerts, setGlobalAlerts] = useState({ enabled: false, frMessage: '' });
    const [showGlobalAlerts, setShowGlobalAlerts] = useState<boolean>(false);
    const language = 'fr';

    const ssrTolgee = useTolgeeSSR(tolgee, language, args.staticData);
    useEffect(() => {
        if (localStorage.getItem('globalAlerts') == null) {
            const lastTry = JSON.parse(localStorage.getItem('lastTryGetGlobalAlerts'));
            if (lastTry == null || new Date() > addMinutes(new Date(lastTry), GLOBAL_ALERTS_RETRY_TIME)) {
                getGlobalAlerts();
            }
        } else {
            const alert = JSON.parse(localStorage.getItem('globalAlerts'));
            const date = new Date(alert.time);
            if (addMinutes(date, GLOBAL_ALERTS_RETRY_TIME) < new Date()) {
                localStorage.removeItem('globalAlerts');
                getGlobalAlerts();
            } else if (AlertsService.checkAllPagesShownPossibility(alert)) {
                setGlobalAlerts(alert);
                setShowGlobalAlerts(true);
            }
        }
        AnalyticsService.initialize();
        AnalyticsService.sendPageView(router.asPath);
    }, []);

    const getGlobalAlerts = () => {
        AlertsService.getGlobalAlerts().then((data) => {
            if (data) {
                data.time = new Date();
                setGlobalAlerts(data);
                localStorage.setItem('globalAlerts', JSON.stringify(data));
                setShowGlobalAlerts(AlertsService.checkAllPagesShownPossibility(data));
            }
            localStorage.setItem('lastTryGetGlobalAlerts', JSON.stringify(new Date()));
        });
    };

    useEffect(() => {
        setShowGlobalAlerts(AlertsService.checkAllPagesShownPossibility(globalAlerts));
    }, [globalAlerts]);

    return (
        <UserProvider>
            <TolgeeProvider tolgee={ssrTolgee}>
                <PotProvider>
                    <ToastProvider
                        components={{ Toast: Toast, ToastContainer: ToastContainer }}
                        placement={'top-right'}
                        autoDismissTimeout={5000}
                    >
                        {showGlobalAlerts && <AlertBanner message={globalAlerts.frMessage} />}
                        <Component {...pageProps} />
                    </ToastProvider>
                </PotProvider>
            </TolgeeProvider>
        </UserProvider>
    );
};
MyApp.getInitialProps = async ({ Component, ctx }) => {
    const locale = 'fr';
    const staticData = {
        [locale]: '',
    };

    await Promise.all(
        [...namespaces].map(async (namespace) => {
            staticData[`${locale}:${namespace}`] = await import(
                `../../public/front-static/static/i18n/${namespace}/${locale}.json`
            );
        }),
    );

    let pageProps = {};

    if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
    }

    return {
        staticData,
        pageProps,
    };
};

export default MyApp;
