import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';

import AuthService from '@services/domain/Lpc/AuthService';
import { getItemFromStorage, setItemToStorage } from '@services/utils/StorageService';

const LOCAL_STORAGE_GA_TAG = 'analytics_active';

const enableAnalyticsCookie = () => {
    setItemToStorage('local', LOCAL_STORAGE_GA_TAG, 'enable');
    initialize();
};

const initialize = () => {
    const cookies = AuthService.getConsentCookie();
    if (cookies != null && cookies.analytics) {
        setItemToStorage('local', LOCAL_STORAGE_GA_TAG, 'enable');
        // @ts-ignore
        window.allow_gtm();
        ReactGA.initialize(process.env.REACT_GOOGLE_ANALYTICS_ID, {
            testMode: process.env.NODE_ENV !== 'production',
            gaOptions: {
                cookieExpires: 60 * 60 * 24 * 30 * 6, // Time in seconds.
                cookieUpdate: false,
            },
        });
    } else {
        return disableAnalyticsCookie();
    }
};

const isAnalyticsActive = (): boolean => {
    return getItemFromStorage('local', LOCAL_STORAGE_GA_TAG) === 'enable';
};

const disableAnalyticsCookie = () => {
    setItemToStorage('local', LOCAL_STORAGE_GA_TAG, 'disable');
    Cookies.remove('_fbp', { path: '/', domain: process.env.COOKIES_DOMAIN });
    Cookies.remove('_ga', { path: '/', domain: document.domain });
    Cookies.remove('_gat', { path: '/', domain: document.domain });
    Cookies.remove('_gcl_au', { path: '/', domain: document.domain });
    Cookies.remove('_gid', { path: '/', domain: document.domain });
    // @ts-ignore
    window.ga = null;
};

const sendPageView = (page) => {
    if (!isAnalyticsActive()) {
        return;
    }
    ReactGA.set({ page: page });
    ReactGA.send(page);
};

const sendEvent = (event) => {
    if (!isAnalyticsActive()) {
        return;
    }
    ReactGA.event(event);
};

export const AnalyticsService = {
    enableAnalyticsCookie,
    disableAnalyticsCookie,
    initialize,
    sendPageView,
    isAnalyticsActive,
    sendEvent,
};
