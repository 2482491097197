import moment from 'moment';

import 'moment/locale/fr';
import { getFirstWordFirstLetterUpperCase } from '@services/utils/StringService';

moment.locale('fr');

export const formatDate = (date: string, separator: string) => {
    if (!date) {
        return;
    }
    return moment(date).format(`DD${separator}MM${separator}YYYY`);
};

export const formatDateToInternational = (date: any, separator: string) => {
    return moment(date).format(`YYYY${separator}MM${separator}DD`);
};

export const formatFullDateToWord = (date) => {
    if (!date) {
        return;
    }

    const regex = /"-"/i;
    const regexDate = date.replace(regex, '/');
    const newDate = moment(regexDate).format('dddd Do MMMM  YYYY');

    return getFirstWordFirstLetterUpperCase(newDate);
};

export const formatDateToWord = (date: string) => {
    if (!date) {
        return;
    }
    const regex = /"-"/i;
    const regexDate = date.replace(regex, '/');
    const newDate = moment(regexDate).format('Do MMMM  YYYY');

    return getFirstWordFirstLetterUpperCase(newDate);
};

export const formatMonthYearToWord = (date) => {
    if (!date) {
        return;
    }

    const regex = /"-"/i;
    const regexDate = date.replace(regex, '/');
    const newDate = moment(regexDate).format('MMMM  YYYY');

    return getFirstWordFirstLetterUpperCase(newDate);
};

export const daysRemaining = (date: string) => {
    if (!date) {
        return;
    }
    const eventDate = moment(date);
    const todayDate = moment();

    return eventDate < todayDate ? 0 : eventDate.diff(todayDate, 'days');
};

const getDateFromTimeStamp = (timeStamp): any => {
    const duration = moment.duration(timeStamp);

    const formattedHours = moment(duration.hours(), 'h').format('HH');
    const formattedMinutes = moment(duration.minutes(), 'm').format('mm');
    const formattedSeconds = moment(duration.seconds(), 's').format('ss');

    return {
        years: duration.years(),
        months: duration.months(),
        days: duration.days(),
        hours: formattedHours,
        minutes: formattedMinutes,
        seconds: formattedSeconds,
    };
};

export const getDurationFromTimeStamp = (timeStamp) => {
    if (!timeStamp) {
        return '';
    }
    const diff = moment().diff(moment(timeStamp), 'milliseconds');
    return getDateFromTimeStamp(diff);
};

export const getDurationFromNow = (timeStamp) => {
    if (!timeStamp) {
        return '';
    }
    const diff = moment(timeStamp).diff(moment(), 'milliseconds');
    return getDateFromTimeStamp(diff);
};

export const getTimeFromNow = (date, setTimeFromNow, t) => {
    const { years, months, days, hours, minutes } = getDurationFromNow(date || '');

    if (years == 0 && months == 0 && days == 0 && hours == 0 && minutes == 0) {
        setTimeFromNow(t('activity.just-now'));
    }
    if (years == 0 && months == 0 && days == 0 && hours == 0 && minutes > 0) {
        setTimeFromNow(t(`activity.minute-ago${Math.abs(minutes) > 1 ? '_plural' : ''}`, { count: Math.abs(minutes) }));
    }
    if (years == 0 && months == 0 && days == 0 && hours > 0) {
        setTimeFromNow(t(`activity.hour-ago${Math.abs(hours) > 1 ? '_plural' : ''}`, { count: Math.abs(hours) }));
    }
    if (days < 0 && (hours > 0 || minutes > 0)) {
        setTimeFromNow(t(`activity.day-ago${Math.abs(days) > 1 ? '_plural' : ''}`, { count: Math.abs(days - 1) }));
    }
    if (days < 0) {
        setTimeFromNow(t(`activity.day-ago${Math.abs(days) > 1 ? '_plural' : ''}`, { count: Math.abs(days) }));
    }
    if (months < 0) {
        setTimeFromNow(t(`activity.month-ago${Math.abs(months) > 1 ? '_plural' : ''}`, { count: Math.abs(months) }));
    }
    if (years < 0) {
        setTimeFromNow(t(`activity.year-ago${Math.abs(years) > 1 ? '_plural' : ''}`, { count: Math.abs(years) }));
    }
};

export const addMinutes = (date, minutes) => {
    date.setMinutes(date.getMinutes() + minutes);
    return date;
};

export default {
    formatDate,
    formatDateToInternational,
    formatFullDateToWord,
    formatDateToWord,
    daysRemaining,
    formatMonthYearToWord,
    getDurationFromNow,
    getDurationFromTimeStamp,
    getTimeFromNow,
    addMinutes,
};
